const name_typing = () => {
  const name = document.getElementById("user_name")

  name.addEventListener("keypress", function(e){
    if (!checkChar(e)) {
      e.preventDefault();
    } else {

      // se o nome estiver com mais espaços, retirar espaços duplicados
      name.value = name.value.replace(/\s+/g, ' ');

      // se o nome começar com espaço, remover espaço:
      if (name.value.charAt(0) == " ")  {
        name.value = name.value.trim();
      }

    }
  });

  name.addEventListener("blur", function(e){

    if (name.value.charAt(name.value.length - 1) == " ") {
      name.value = name.value.trimEnd();
    }
  });

  function checkChar(e) {

    var char = String.fromCharCode(e.keyCode);

    console.log(char);
    var pattern = /^[a-zA-Z\s]$/;
    if (char.match(pattern)) {
      return true;
    }
  };
};

export { name_typing };
