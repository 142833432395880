const email_typing = () => {
  const email = document.getElementById("user_email")

  email.addEventListener("blur", function(e){

      email.value = email.value.replace(/\s/g, '');

  });

};

export { email_typing };
