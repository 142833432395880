import { createDependency } from "webpack/lib/SingleEntryPlugin";

const cpf_typing = () => {
  const cpf = document.getElementById("user_cpf")

  // cpf.addEventListener("keypress", function(e){
  //   if (!checkChar(e)) {
  //     e.preventDefault();
  //   }
  // });

  cpf.addEventListener("blur", function(){
          if(/^[0-9][0-9][0-9][0-9][0-9][0-9][0-9][0-9][0-9][0-9][0-9]$/.test(cpf.value)) {
                  cpf.value = cpf.value.match(/.{1,3}/g).join(".").replace(/\.(?=[^.]*$)/,"-");
          }
  });

  // function checkChar(e) {

  //   var char = String.fromCharCode(e.keyCode);

  //   console.log(char);
  //   var pattern = /^\d$/;
  //   if (char.match(pattern)) {
  //     return true;
  //   }
  // };
};

export { cpf_typing };
