// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

require("@rails/ujs").start()
require("turbolinks").start()
require("@rails/activestorage").start()
require("channels")
require("jquery")

// = require activestorage

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)


// ----------------------------------------------------
// Note(lewagon): ABOVE IS RAILS DEFAULT CONFIGURATION
// WRITE YOUR OWN JS STARTING FROM HERE 👇
// ----------------------------------------------------

// External imports
import "bootstrap";

import select2 from 'select2';

document.addEventListener('turbolinks:load', () => {
  $('.multiselect-bamboleio').each( function() {
    if($(this)) $(this).select2();
  });
});


// Internal imports, e.g:
// import { initSelect2 } from '../components/init_select2';

import { cpf_typing } from '../controllers/cpf_controller'

import { mobile_phone_typing } from '../controllers/mobile_phone_typing_controller'

import { name_typing } from '../controllers/name_validation_controller'

import { last_name_typing } from '../controllers/last_name_validation_controller'

import { email_typing } from '../controllers/email_validation_controller'

import { formProcess} from '../controllers/keep_form_inputs_controller.js'

document.addEventListener('turbolinks:load', () => {
  // Call your functions here, e.g:
  if (document.getElementById("user_cpf")) {
  	cpf_typing();
  }
});

document.addEventListener('turbolinks:load', () => {
  // Call your functions here, e.g:
  if (document.getElementById("user_mobile_phone")) {
  	mobile_phone_typing();
  }
});

document.addEventListener('turbolinks:load', () => {
  // Call your functions here, e.g:
  if (document.getElementById("user_name")) {
  	name_typing();
  }
});

document.addEventListener('turbolinks:load', () => {
  // Call your functions here, e.g:
  if (document.getElementById("user_last_name")) {
  	last_name_typing();
  }
});

document.addEventListener('turbolinks:load', () => {
  // Call your functions here, e.g:
  if (document.getElementById("user_email")) {
  	email_typing();
  }
});

document.addEventListener('turbolinks:load', () => {
  // Call your functions here, e.g:
  if (document.getElementById("filter-form")) {
  	formProcess();
  }
});

document.addEventListener('turbolinks:load', () => {
  $('.js-example-basic-single').select2();
});

document.addEventListener('turbolinks:load', () => {
  $('.js-example-basic-multiple').each(function() {
    if($(this)) $(this).select2();
  });
});
