const mobile_phone_typing = () => {
  const mobile = document.getElementById("user_mobile_phone")

  mobile.addEventListener("keypress", function(e){
    if (!checkChar(e)) {
      e.preventDefault();
    } else {

      // se o nome estiver com mais espaços, retirar espaços duplicados
      // mobile.value = mobile.value.replace(/\s+/g, ' ');

      // se o nome começar com espaço, remover espaço:
      if (mobile.value.charAt(0) == " ")  {
        mobile.value = mobile.value.trim();
      }

    }

    console.log("teste")
  });

  mobile.addEventListener("blur", function(e){

    mobile.value = mobile.value.replace(/\s/g, '');

  });

  function checkChar(e) {

    var char = String.fromCharCode(e.keyCode);

    console.log(char);
    var pattern = /^[0-9]$/;
    if (char.match(pattern)) {
      return true;
    }
  };
};

export { mobile_phone_typing };
